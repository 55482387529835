import React from 'react';
import { Link } from 'gatsby';

import { BaseTemplate } from '@templates';
import { SEO, PageSection } from '@components';

import './index.scss';

const sections = [
  {
    id: 'financial-and-professional-services',
    label: 'Financial and professional services'
  },
  {
    id: 'ip-infringement-regulated-or-illegal-products-and-services',
    label: 'IP infringement, regulated or illegal products and service'
  },
  {
    id: 'unfair-predatory-or-deceptive-practices',
    label: 'Unfair, predatory, or deceptive practices'
  },
  {
    id: 'products-or-services-that-are-otherwise-restricted-by-our-financial-partners',
    label: 'Products or services that are otherwise restricted by our financial partners'
  }
];

const sidebar = sections.map((section, index) => (
  <li key={`terms-sidebar-item-${index + 1}`} className="sidebar-item">
    <Link className="sidebar-link" to={`/restricted-businesses#${section.id}`}>
      {`${section.label}`}
    </Link>
  </li>
));

const IndexPage = () => (
  <BaseTemplate>
    <SEO title="Restricted Businesses" />
    <PageSection className="subpage-restricted-businesses">
      <div className="restricted-businesses-container">
        <div className="sidebar display-none-is-mobile">
          <h4 className="text-sh700 sidebar-header">On this page</h4>
          <ul className="section-title-list">{sidebar}</ul>
          <h4 className="text-sh700 sidebar-header">Other documents</h4>
          <ul className="section-title-list">
            <li className="sidebar-item">
              <Link className="sidebar-link" to="/terms">Terms of Use</Link>
            </li>
            <li className="sidebar-item">
              <Link className="sidebar-link" to="/privacy">Privacy Policy</Link>
            </li>
            <li className="sidebar-item">
              <Link className="sidebar-link" to="/third-parties">List of Third Parties</Link>
            </li>
          </ul>
        </div>

        {/* TODO: could be separated per section */}
        <div className="content">
          <div className="title">
            <h2>Restricted Businesses</h2><br />
            <h4>Last updated: July 3, 2020</h4>
          </div>

          <div className="section">
            <p>
              The following categories of businesses and business practices are restricted from using
              the PayMongo Service (“Restricted Businesses”). Restricted Business categories may be
              imposed through Network Rules or the requirements of our Financial Services Providers.
              In certain cases, businesses listed below may be eligible for processing with explicit
              prior approval from PayMongo. Note, however, that businesses that offer illegal products
              or services are never eligible to use the PayMongo Services. The types of businesses listed
              in the right column are representative, but not exhaustive. If you are uncertain as to
              whether your business is a Restricted Business, or have questions about how these requirements
              apply to you, please <a href="mailto:legal@paymongo.com">contact us</a>.
            </p>
            <p class="content-highlight">
              By registering with us, you are confirming that you will not use the Service to accept
              payments in connection with the following businesses, business activities, or business
              practices, unless you have received prior written approval from PayMongo.
            </p>
          </div>

          <div id="financial-and-professional-services" className="section">
            <p className="section-title">Financial and professional services</p>
            <table>
              <tbody>
                <tr>
                  <td width="30%">
                    Investment & credit services
                  </td>
                  <td>
                    Securities brokers; mortgage consulting or debt reduction services; credit counselling
                    or repair; real estate opportunities; lending instruments
                  </td>
                </tr>

                <tr>
                  <td>
                    Money and legal services
                  </td>
                  <td>
                    Financial institutions, money transmitters and money services businesses, check cashing,
                    wire transfers, money orders; currency exchanges or dealers; bill-pay services; crowdfunding;
                    insurance; bail bonds; collections agencies; law firms collecting funds for any purpose other
                    than to pay fees owed to the firm for services provided by the firm (e.g., firms cannot use
                    PayMongo to hold client funds, collection or settlement amounts, disputed funds, etc.)
                  </td>
                </tr>

                <tr>
                  <td>Virtual currency or stored value</td>
                  <td>
                    Virtual currency that can be monetized, resold, or converted to physical or digital products
                    and services or otherwise exit the virtual world (e.g., Bitcoin); cryptocurrency mining equipment;
                    initial coin offerings; digital wallets, sale of stored value or credits maintained, accepted and
                    issued by anyone other than the seller
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div id="ip-infringement-regulated-or-illegal-products-and-services" className="section">
            <p className="section-title">IP infringement, regulated or illegal products and services</p>
            <table>
              <tbody>
                <tr>
                  <td width="30%">
                    Intellectual property or proprietary rights infringement
                  </td>
                  <td>
                    Sales, distribution, or access to counterfeit music, movies, software, or other licensed
                    materials without the appropriate authorization from the rights holder; any product or
                    service that directly infringes or facilitates infringement upon the trademark, patent,
                    copyright, trade secrets, or proprietary or privacy rights of any third party; use of
                    PayMongo intellectual property without prior written consent from PayMongo; use of the
                    PayMongo name or logo, or in a manner that otherwise harms PayMongo or the PayMongo brand;
                    any action that implies an untrue endorsement by or affiliation with PayMongo
                  </td>
                </tr>

                <tr>
                  <td>
                    Counterfeit or unauthorized goods
                  </td>
                  <td>
                    Counterfeit goods; unauthorized sale or resale of brand name or designer products or
                    services; sale of goods or services that are illegally imported or exported
                  </td>
                </tr>

                <tr>
                  <td>Gambling</td>
                  <td>
                    Lotteries; bidding fee auctions; sports forecasting or odds making for a monetary or material
                    prize; fantasy sports leagues with cash prizes; internet gaming; contests; sweepstakes; games
                    of chance including legal or illegal forms of gambling, internet gambling, sweepstakes and
                    contests with a buy-in or cash prize; charity sweepstakes and raffles for the explicit purpose
                    of fundraising
                  </td>
                </tr>

                <tr>
                  <td>Regulated or illegal products or services</td>
                  <td>
                    Cannabis dispensaries and related businesses; sale of tobacco, e-cigarettes, and e-liquid;
                    online pharmacies; prescription-only products including card-not-present pharmaceuticals;
                    peptides and research chemicals; fake references or ID-providing services; age restricted
                    goods or services; weapons and munitions; gunpowder and other explosives; fireworks and related
                    oods; toxic, flammable, and radioactive materials; products and services with varying legal
                    status on a state-by-state basis; goods or services, the sale of which is illegal under applicable
                    law in the jurisdictions to which your business is targeted or directed
                  </td>
                </tr>
                <tr>
                  <td>Adult content and services</td>
                  <td>
                    Pornography and other obscene materials (including literature, imagery and other media)
                    depicting nudity or explicitly sexual acts; sites offering any sexually- related services
                    such as prostitution, escorts, pay-per view, adult live chat features; sexually oriented
                    items (e.g., adult toys); adult video stores and sexually oriented massage parlors; gentleman’s
                    clubs, topless bars, and strip clubs; sexually oriented dating services
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div id="unfair-predatory-or-deceptive-practices" className="section">
            <p className="section-title">Unfair, predatory or deceptive practices</p>
            <table>
              <tbody>
                <tr>
                  <td width="30%">
                    Get rich quick schemes
                  </td>
                  <td>
                    Investment opportunities or other services that promise high rewards
                  </td>
                </tr>

                <tr>
                  <td>
                    Mug shot publication or pay-to- remove sites
                  </td>
                  <td>
                    Platforms that facilitate the publication and removal of content (such as mug shots), where
                    the primary purpose of posting such content is to cause or raise concerns of reputational harm
                  </td>
                </tr>

                <tr>
                  <td>No-value-added services</td>
                  <td>
                    Sale or resale of a service without added benefit to the buyer; resale of government offerings
                    without authorization or added value; sites that we determine in our sole discretion to be unfair,
                    deceptive, or predatory towards consumers
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div id="products-or-services-that-are-otherwise-restricted-by-our-financial-partners" className="section">
            <p className="section-title">Products or services that are otherwise restricted by our financial partners</p>
            <table>
              <tbody>
                <tr>
                  <td width="30%">
                    Aggregation
                  </td>
                  <td>
                    Engaging in any form of licensed or unlicensed aggregation of funds owed to third parties, factoring,
                    or other activities intended to obfuscate the origin of funds; payment facilitation
                  </td>
                </tr>
                <tr>
                  <td>
                    Drug paraphernalia
                  </td>
                  <td>
                    Any equipment designed for making or using drugs, such as bongs, vaporizers, and hookahs
                  </td>
                </tr>
                <tr>
                  <td>High risk businesses</td>
                  <td>
                    Bankruptcy lawyers; remote technical support; psychic services; essay mills; chain letters; door-to-door
                    sales; medical benefit packages; telemedicine and telehealth services; travel reservation services and
                    clubs; airlines; cruises; timeshares; circumvention, jamming and interference devices; prepaid phone
                    credits, phone services; telemarketing, offering substantial rebates or special incentives as an inducement
                    to purchase products or services; telecommunications manipulation equipment; forwarding brokers; negative
                    response marketing; subscriptions over one year; extended warranties; government grants; embassy, foreign
                    consultate, or other foreign governments; charities without proper registration; credit card and identity
                    theft protection; the use of credit to pay for lending services; any businesses that we believe poses
                    elevated financial risk, legal liability, or violates card network or bank policies; any business or
                    organization that a. engages in, encourages, promotes or celebrates unlawful violence or physical harm
                    to persons or property, or b. engages in, encourages, promotes or celebrates unlawful violence toward any
                    group based on race, religion, disability, gender, sexual orientation, national origin, or any other
                    immutable characteristic
                  </td>
                </tr>
                <tr>
                  <td>Multi-level marketing</td>
                  <td>
                    Pyramid schemes and multi-level marketing
                  </td>
                </tr>
                <tr>
                  <td>Pseudo pharmaceuticals</td>
                  <td>
                    Nutraceuticals, pseudo-pharmaceuticals and other products that make health claims that have not been
                    approved or verified by the applicable local and/or national regulatory body
                  </td>
                </tr>
                <tr>
                  <td>Social media activity</td>
                  <td>
                    Sale of Twitter followers, Facebook likes, YouTube views, and other forms of social media activity
                    and online traffic
                  </td>
                </tr>
                <tr>
                  <td>Substances designed to mimic illegal drugs</td>
                  <td>
                    Sale of a legal substance that provides the same effect as an illegal drug (e.g., salvia, kratom)
                  </td>
                </tr>
                <tr>
                  <td>Video game or virtual world credits</td>
                  <td>
                    Sale of in-game currency unless the merchant is the operator of the virtual world
                  </td>
                </tr>
                <tr>
                  <td>
                    Use of PayMongo in a manner inconsistent with its intended use or as expressly prohibited
                    in the PayMongo Terms of Use
                  </td>
                  <td>
                    Use of PayMongo principally as a virtual terminal (e.g., submitting card transactions by manually
                    inputting card information); processing where there is no bona fide good or service sold, or donation
                    accepted; card testing; evasion of card network chargeback monitoring programs; cross-border acquiring;
                    sharing cardholder information with another merchant for payment cross-sell product or service
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              If you have any questions about this list or if you business is supported,
              don't hesitate to <a href="mailto:support@paymongo.com">get in touch</a>.
            </p>
          </div>
        </div>
      </div>
    </PageSection>
  </BaseTemplate>
);

export default IndexPage;
